import { ReduceStore } from 'flux/utils';
import Dispatcher from '../Dispatcher';
import {
    buildAsyncReducer,
    asyncInitialState,
    asyncStatus,
    asyncReducerStatus,
    isLoading,
    isError,
    isLoaded
} from "./utils/utils/asyncReducer";
import NPIActions from './NPIActions';
import LocationActions from '../LocationActions';
import api from "../../lib/api";
import LocationStore from "../LocationStore";
import PerformanceYearStore from "../PerformanceYearStore";
import PerformanceYearActions from "../PerformanceYearActions";
import moment from "moment-timezone";

const initialState = {
    ...asyncInitialState,
    paramHash: "",
    data: {
        yearData: {},
        npiData: [],
        conflictsData: {}
    }
};

export const getYear = (state) => {
    return state.data.yearData || {};
};

export const hasConflicts = (state) => {
    return !!state.data.conflictsData.hasConflict;
};

export const getConflicts = (state) => {
    if(hasConflicts(state)) {
        return state.data.conflictsData.conflicts || [];
    }
    return [];
};

export const getNPIs = (state) => {
    return state.data.npiData || [];
};

export const getNPI = (state, id) => {
    return getNPIs(state).find((it) => it.id.toString() === id);
};

const npiAsyncReducer = buildAsyncReducer(NPIActions, initialState);

const requestNPIData = (year, orgKey, implKey) => {
    // we need to call multiple apis to get all the data needed for this store.
    const yearData = PerformanceYearStore.getSelectedPerformanceYear();
    return api.loadSubmitNPIData(year, orgKey, implKey)
        .then((res) => {
            // tin: a npi: b could not be processed due to a conflict
            const npiData = res.data.summaries.map(it => ({
                ...it,
                entityType: it.submitter.entityType,
                tin: it.submitter.tin,
                npi: it.submitter.npiList.map(it => `${it.npi}`).join(", "),
                name: it.submitter.npiList.map(it => `${it.name}`).join(", ")
            }));
            const conflictsData = res.data.conflicts;
            Dispatcher.dispatch({ type: NPIActions.LOADED, data: {yearData, npiData, conflictsData} });
            return Promise.resolve(res);
        })
        .catch((err) => { // error!
            Dispatcher.dispatch({ type: NPIActions.ERROR, error: err });
            return Promise.reject(err);
        });
};

class NPIStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {...initialState};
    }

    reduce(state, action) {
        // standard async actions
        let match;
        switch(action.type) {
            case PerformanceYearActions.LOADED:
            case LocationActions.LOCATION_CHANGED:
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                if(!["MIPS", "DRAFT", "RECEIPT"].includes(LocationStore.getRouteKey())) {
                    return state; // we only need this data for the above views.
                }
                match = LocationStore.getMatch();
                if (match && match.params) {
                    const year = match.params.year;
                    const orgKey = match.params.orgKey;
                    const implKey = match.params.implKey;
                    const nextHash = [year,orgKey,implKey].join("/");
                    if(
                        state.paramHash === nextHash &&
                        state.status === asyncStatus.LOADED &&
                        LocationStore.getRouteKey() !== "HOME" // refresh every time home is loaded.
                    ) {
                        // data is already loaded for this hash.
                        return state;
                    }
                    requestNPIData(
                        year,
                        orgKey,
                        implKey
                    );
                    // start the loading
                    return {
                        ...(state.paramHash !== nextHash ? this.getInitialState() : state),
                        paramHash: nextHash, // only reset state when the url params change.
                        status: asyncStatus.LOADING
                    };
                }
                // something went wrong with the url params.
                return state;
            default:
                return npiAsyncReducer(state, action);
        }
    }

    isLoading() {
        return isLoading(this.getStatus());
    }

    isError() {
        return isError(this.getStatus());
    }

    isLoaded() {
        return isLoaded(this.getStatus());
    }

    getStatus() {
        return asyncReducerStatus(this.getState());
    }

    getYear(id) {
        let yearInfo = getYear(this.getState());
        if(id) {
            const npi = this.getNPI(id);
            if (npi) {
                const hasSubmitOverrides = npi.submitter.submissionOpen && npi.submitter.submissionClose;
                if (hasSubmitOverrides) {
                    yearInfo = {
                        ...yearInfo,
                        submissionOpen: npi.submitter.submissionOpen,
                        submissionClose: npi.submitter.submissionClose
                    };
                }
            }
        }
        return yearInfo;
    }

    hasConflicts() {
        return hasConflicts(this.getState());
    }

    getConflicts() {
        return getConflicts(this.getState());
    }

    getNPIs() {
        return getNPIs(this.getState());
    }

    getNPI(id) {
        return getNPI(this.getState(), id);
    }

    canSubmitNPI(id) {
        let canSubmit = PerformanceYearStore.canSubmit();
        const npi = this.getNPI(id);
        if(npi) {
            const hasSubmitOverrides = !!npi.submitter.submissionOpen && !!npi.submitter.submissionClose;
            if (hasSubmitOverrides) {
                if (moment().isBetween(
                    moment(npi.submitter.submissionOpen),
                    moment(npi.submitter.submissionClose))
                ) {
                    canSubmit = true;
                }
                else {
                    canSubmit = false;
                }
            }
        }
        return canSubmit;
    }
}

export default new NPIStore();
