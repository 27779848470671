import React from "react";
import StatusNew from "./StatusNew";
import VerificationManagerEditView from "../../../Verification/Details/VerificationManagerEditView";

const StatusComplete = (props) => {
    if (props.view === "verification") {
        return (
            <VerificationManagerEditView {...props} />
        );
    }
    // for group allow editing of NPIs
    return (
        <StatusNew {...props} />
    );
};
export default StatusComplete;
