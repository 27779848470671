import React from "react";
import {darken} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {formatMessage, Paper, Main, Spinner} from "@armus/armus-dashboard";
import Container from "../../../data/Container";
import NPIStore from "../../../data/submit/NPIStore";
import UserStore from "../../../data/UserStore";
import {buildUrl} from "../../../../routes";
import history from "../../../lib/history";
import ErrorPage from "../../ErrorPage";
import {homeBreadcrumb} from "../Home/Home";
import api from "../../../lib/api";
import RenderObject from "../../components/RenderObject";
import ProgressStepper from "./components/ProgressStepper";
import StatusNew from "./components/StatusNew";
import StatusInvalid from "./components/StatusInvalid";
import StatusVerificationPending from "./components/StatusVerificationPending";
import StatusVerified from "./components/StatusVerified";
import StatusComplete from "./components/StatusComplete";
import StatusSignaturePending from "./components/StatusSignaturePending";
import {getStatus, getTypeLabel} from "../components/utils";
import moment from "moment-timezone";
import {notificationsState} from "../../components/Notifications";

const getInitialConsentShape = () => {
    return {
        "id": null,
        "entityType": "individual",
        "status": "NEW",
        "tin": null,
        "groupName": null,
        "signerName": null,
        "signerEmail": null,
        "envelopeId": null,
        "uploadedVerificationDocumentBucketKey": null,
        "tinDataBoundStartDate": moment().startOf("year").format("YYYY-MM-DD"),
        "tinDataBoundEndDate": moment().endOf("year").format("YYYY-MM-DD"),
        "verificationMessage": null,
        "npiList": [{
            name: "",
            npi: ""
        }]
    };
};

const useStyles = makeStyles((theme) => ({
    content: {
        height: "100%",
        overflow: "scroll",
        paddingBottom: 8*10
    },
    paper: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    subtitle: {
        marginBottom: theme.spacing(2)
    },
    tabsContainer: {
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
        flexGrow: 1
    },
    tabs: {
        borderBottom: "1px solid " + darken(theme.palette.background.default, 0.25),
        boxShadow: "0 2px 2px rgba(0,0,0,.25)",
        "& .MuiTabs-flexContainer": {
            borderBottom: "3px solid " + theme.palette.background.default

        },
        "& .MuiTab-root.Mui-selected": {
            fontWeight: "bold"
        },
        "& .MuiTabs-indicator": {
            height: 3
        }
    }
}));

export const consentDetailsBreadcrumb = (view = "consent", year, org = {}, impl = {}, submitter) => {
    let title;
    if(submitter.status === "NEW") {
        title = "New " + getTypeLabel(submitter.entityType);
    }
    else {
        title = submitter.npiList.map(it => it.name).join(", ");
    }
    return ({
        label: formatMessage({
                id: "consent.details.breadcrumb",
                defaultMessage: "{year} {orgName} {implName} - {title} "
            },
            {orgName: org.name, implName: impl.name, year, title}
        ),
        url: buildUrl(view !== "verification" ? "CONSENT_DETAILS" : "VERIFICATION_DETAILS", {view: view, year, orgKey: org.key, implKey: impl.key})
    });
};

const Details = ({
    isValidURL,
    consentId,
    year,
    view,
    organization,
    implementation,
    onClose
}) => {
    const [data, setData] = React.useState(getInitialConsentShape());
    const [isFirstLoad, setIsFirstLoad] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [errors, setErrors] = React.useState([]);
    const classes = useStyles();
    let isNew = false;
    let type = data.entityType || "individual";
    if(consentId.indexOf("new") === 0) {
        isNew = true;
        type = consentId.split("-")[1];
        data.entityType = type;
    }

    const id = data.id !== null ? data.id : isNaN(consentId) ? null : consentId;
    const hasId = id !== null;

    const fetchConsentDetails = React.useCallback(async (showLoading = true) => {
        if(!hasId) {
            setIsFirstLoad(false);
            setIsLoading(false);
            return false;
        }
        setIsLoading(showLoading);
        const res = await api.loadConsentDetailsData(year, organization.key, implementation.key, id);
        if(res.status === 200) {
            setData(res.data);
        }
        setIsLoading(false);
        setIsFirstLoad(false);
    }, [year, organization.key, implementation.key, hasId, id]);

    React.useEffect(() => {
        fetchConsentDetails();
    }, [fetchConsentDetails, isNew]);

    if (!isValidURL) {
        // the url is invalid show 404 page.
        return <ErrorPage statusCode={404}/>;
    }

    const handleSave = async (saveData) => {
        let res;
        try {
            if (saveData.id === null) {
                // create
                res = await api.createConsentData(year, organization.key, implementation.key, saveData);
            } else {
                // update
                res = await api.updateConsentData(year, organization.key, implementation.key, id, saveData);
            }
            if (res.status === 200) {
                notificationsState.onPush({
                    type: "success",
                    message: "Successfully Saved!",
                    stacked: true,
                    size: "medium",
                    horizontalPosition: "right",
                    verticalPosition: "top"
                });
                setData(res.data);
                setErrors([]);
            }
        }
        catch(error) {
            console.log(error);
            notificationsState.onPush({
                type: "error",
                message: "Failed to save!",
                stacked: true,
                size: "medium",
                horizontalPosition: "right",
                verticalPosition: "top"
            });
            const d = error?.response?.data || { message: error.message, validationMessages: [] };
            setErrors([d.message].concat(d.validationMessages || []));
            throw error;
        }
        return res;
    };

    const breadcrumb = consentDetailsBreadcrumb(view, year, organization, implementation, data);
    let body = null;
    let childProps = {
        isLoading,
        view: view,
        year: year,
        orgKey: organization.key,
        implKey: implementation.key,
        data: data,
        onLoad: fetchConsentDetails,
        onClose: onClose,
        onSave: handleSave
    };
    switch(data.status) {
        case "NEW":
            body = <StatusNew {...childProps} />;
            break;
        case "PENDING_VERIFICATION":
            body = <StatusVerificationPending  {...childProps} />;
            break;
        case "INVALID":
            body = <StatusInvalid  {...childProps} />;
            break;
        case "VERIFIED":
            body = <StatusVerified  {...childProps} />;
            break;
        case "PENDING_SIGNATURE":
            body = <StatusSignaturePending  {...childProps} />;
            break;
        case "COMPLETE":
            body = <StatusComplete  {...childProps} />;
            break;
        default:
            // TODO: return null / unknown status
            body = (
                <Box m={8}>
                    New: {isNew ? "TRUE" : "FALSE"} - {type}<br/><br/>
                    <RenderObject data={data}/>
                </Box>
            );
    }

    const statusInfo = getStatus(data.status);

    const title = view !== "verification" ? "Submitting Entity" : "Verification";

    if(isFirstLoad) {
        return <Spinner variant={"linear"} />;
    }

    return (
        <Main breadcrumbs={[{label: "Home", url: "/"}, homeBreadcrumb(view, year, organization, implementation), breadcrumb]} fullWidth={true} fullHeight={true}>
            <Box className={classes.content}>
                <Paper className={classes.paper}>
                    <Box  p={3}>
                         <Grid container spacing={2} flex={true}>
                             <Grid item xs>
                                 <Typography variant={"h1"} mb={2}>
                                     <ArrowBack
                                         fontSize={"large"}
                                         sx={{marginRight: 1, verticalAlign: "middle"}}
                                         onClick={() => onClose(false)}
                                     />
                                     {isNew ? `New ${getTypeLabel(type)} ${title}` : `${getTypeLabel(type)} ${title} Details `}
                                 </Typography>
                                 <Typography>
                                     {!isNew && <>
                                        {data.entityType === "group" && <>Group Name: <strong>{data.groupName}</strong>&nbsp;<br/></>}
                                         TIN: <strong>{data.tin}</strong>&nbsp;<br/>
                                         NPI: <strong>{data.npiList.map(it => it.npi).join(", ")}</strong>&nbsp;<br/>
                                         Names: <strong>{data.npiList.map(it => it.name).join(", ")}</strong>&nbsp;<br/>
                                     </>}
                                     Type: <strong>{getTypeLabel(data.entityType)}</strong>&nbsp;<br/>
                                     Status: <strong><Typography component="span" fontWeight={500} color={statusInfo.color}>{statusInfo.value}</Typography></strong>
                                 </Typography>
                             </Grid>
                             <Grid item xs={2.25}>
                             </Grid>
                         </Grid>
                    </Box>
                </Paper>

                <ProgressStepper isLoading={isLoading} status={data.status} />
                {errors.map((message, index) => {
                    return (
                        <Typography color={"error.main"} fontSize={index === 0 ? "large" : "medium"} textAlign={"left"} my={1} sx={{maxWidth: "75%", ml: "12.5%"}}>
                            {index === 0 ? "" : `${index}.`} {message}
                        </Typography>
                    );
                })}
                {isLoading && <Spinner variant={"linear"} />}
                {body}
            </Box>
        </Main>
    );
};

export default Container(
    Details,
    () => [
        UserStore,
        NPIStore
    ],
    (state, props) => {
        const params = props.match.params;
        const view = params.view;
        const year = params.year;
        const consentId = params.consentId;
        const orgKey = params.orgKey;
        const implKey = params.implKey;
        const org = UserStore.getOrganization(orgKey);
        const impl = UserStore.getImplementation(orgKey, implKey);
        const isValidURL = !!org && !!impl;
        return {
            ...props,
            isValidURL,
            consentId,
            year,
            view,
            organization: org || {},
            implementation: impl || {},
            isLoading: UserStore.isLoading(),
            onClose: () => {
                const url = buildUrl(view.toUpperCase(), {view: view, year: year, orgKey: orgKey, implKey: implKey});
                history.push(url);
            }
        };
    }
);
