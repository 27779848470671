import React from "react";
import {Button, DatePicker,  Paper} from "@armus/armus-dashboard";
import Typography from "@mui/material/Typography";
import {Box, Grid} from "@mui/material";
import VerificationManagerApproveOrRejectView from "./VerificationManagerApproveOrRejectView";
import moment from "moment-timezone";
import PerformanceYearStore from "../../../data/PerformanceYearStore";
import Container from "../../../data/Container";

const VerificationManagerEditView = (props) => {
    const {data, onClose, onSave, yearInfo} = props;
    const [localData, setLocalData] = React.useState(data);
    const [hasChanges, setHasChanges] = React.useState(false);

    const handleChange = (key, value) => {
        setHasChanges(true);
        setLocalData((old) => ({...old, [key]: value}));
    };

    let validDateRange = true; // allow verification manager to remove submission window if already set.
    const validationMessages = [];
    if(
        (!localData.submissionOpen && !!localData.submissionClose) ||
        (!!localData.submissionOpen && !localData.submissionClose)
    ) {
        validDateRange = false;
        validationMessages.push('Submission window must have both dates filled out.');
    }
    else if(!!localData.submissionOpen && !!localData.submissionClose) {
        const diff = moment(localData.submissionClose).add('second', 1).diff(moment(localData.submissionOpen), 'days');
        if(diff === 0) {
            validDateRange = false;
            validationMessages.push('Submission window must be greater than 24 hours.');
        }
        if(diff < 0) {
            validDateRange = false;
            validationMessages.push('Submission Start Date must be less than Submission End Date.');
        }
    }

    const canSave = hasChanges && validDateRange;
    const handleSave = () => {
        onSave(localData);
    };
    return (
        <>
            <Paper p={4} sx={{maxWidth: "75%", ml: "12.5%", mb: 3}}>
                <Typography variant={"h3"} mb={2}>Edit Submission Window</Typography>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>
                        <Box ml={2} mb={0}>
                            <Typography fontWeight={600}>Submission Start Date:</Typography>
                        </Box>
                        <DatePicker
                            value={localData.submissionOpen}
                            size={"large"}
                            minDate={moment(yearInfo.submissionOpen)}
                            maxDate={moment(yearInfo.submissionClose)}
                            onChange={(e, v) => handleChange("submissionOpen", v instanceof moment ? v.format("YYYY-MM-DDTHH:mm:ss.SSSZ") : v)}
                        />
                    </Grid>
                    <Grid item xs={7} alignSelf={"center"}>
                        <Box pl={2} mt={3}>Use these date ranges to override the default submission window for this TIN.</Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>
                        <Box ml={2} mb={0}>
                            <Typography fontWeight={600}>Submission End Date:</Typography>
                        </Box>
                        <DatePicker
                            value={localData.submissionClose}
                            size={"large"}
                            minDate={moment(yearInfo.submissionOpen)}
                            maxDate={moment(yearInfo.submissionClose)}
                            onChange={(e, v) => handleChange("submissionClose", v instanceof moment ? v.add('second', 86399).format("YYYY-MM-DDTHH:mm:ss.SSSZ") : v)}
                        />
                    </Grid>
                </Grid>
                <Box my={2}>
                    {validationMessages.map(message => <Box key={message} color={"error.main"}>{message}</Box>)}
                </Box>
                <Box textAlign={"right"} mt={4}>
                    <Button color={"secondary"} size={"large"} onClick={() => onClose()}>Cancel</Button> &nbsp;
                    <Button
                        color={"primary-alt"}
                        size={"large"}
                        onClick={handleSave}
                        disabled={!canSave}
                    >
                        Submit
                    </Button>
                </Box>
            </Paper>

            <VerificationManagerApproveOrRejectView {...props} showActions={false}/>
        </>
    );
};

export default Container(
    VerificationManagerEditView,
    () => [
        PerformanceYearStore
    ],
    (state, props) => {
        const yearInfo = PerformanceYearStore.getSelectedPerformanceYear();
        return {
            ...state,
            ...props,
            yearInfo
        };
    }
);
