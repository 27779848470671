import React from "react";
import {Button, Paper} from "@armus/armus-dashboard";
import Typography from "@mui/material/Typography";
import {getTypeLabel} from "../../components/utils";
import VerificationManagerApproveOrRejectView from "../../../Verification/Details/VerificationManagerApproveOrRejectView";
import {Box} from "@mui/material";
import {Download1500Form} from "./StatusNew";
import {alpha} from "@mui/material/styles";

const StatusVerificationPending = (props) => {
    const {data, year, orgKey, implKey, onClose} = props;
    if (props.view === "verification") {
        return (
            <VerificationManagerApproveOrRejectView {...props} />
        );
    }
    return (
        <Paper p={4}  sx={{maxWidth: "75%", ml: "12.5%", mb: 3}}>

            <Typography variant={"h3"}>{getTypeLabel(data.entityType)} Verification Pending</Typography>
            <Typography variant={"subtitle1"} sx={{opacity: .5, mb: 2}}>
                Your submitting entity is pending verification. Please check back in 1-2 business days.
            </Typography>
            <Typography variant={"h3"} fontWeight={500} sx={{my: 2}}>TIN Information</Typography>
            <Box sx={{
                border: "1px solid #dddddd",
                background: alpha("#888888", .05),
                py: 2,
                px: 4,
                borderRadius: 4,
                textAlign: "left",
                mb: 2,
                overflow: "hidden"
            }}>
                {data.entityType === "group" && <h4>Group Name: {data.groupName}</h4>}
                <h4>TIN: {data.tin}</h4>
                Start Date: {data.tinDataBoundStartDate}<br/>
                End Date: {data.tinDataBoundEndDate}<br/>
            </Box>

            <Typography variant={"h3"} fontWeight={500} sx={{my: 2}}>NPI Information</Typography>
            <Box sx={{
                border: "1px solid #dddddd",
                background: alpha("#888888", .05),
                py: 2,
                px: 4,
                borderRadius: 4,
                textAlign: "left",
                mb: 2,
                overflow: "hidden"
            }}>
                {data.npiList.map((npi) => (
                    <Box sx={{borderBottom: "1px solid #dddddd", py: 2}}>
                        <h4>NPI: {npi.npi}</h4>
                        Name: {npi.name}<br/>
                    </Box>
                ))}
            </Box>

            <Download1500Form year={year} orgKey={orgKey} implKey={implKey} data={data} />
            <Box
                textAlign={"right"}
                mt={4}
            >
                <Button
                    color={"secondary"}
                    size={"large"}
                    onClick={onClose}
                >
                    Back
                </Button> &nbsp;
            </Box>
        </Paper>
    );
};
export default StatusVerificationPending;


