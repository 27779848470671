import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {EnhancedTable, Paper} from "@armus/armus-dashboard";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import {getStatus, getTypeLabel} from "../../Signup/components/utils";
import {Person} from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/People";
import Link from "@mui/material/Link";
import api from "../../../lib/api";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
    scoreChip: {
        minWidth: 50,
        fontWeight: "bold",
        fontSize: 14
    },
    enhancedTable: {
        "& > div": {
            minHeight: 0
        }
    }
}));

const buildTableColumns = (classes, type, on1500FormClick, yearInfo) => {
    return [
        {
            id: "icon",
            label: " ",
            isSortable: false,
            props: {width: "1%", align: "center", sx: {pl: 4, minWidth: 50, color: '#888888' }},
            Content: ({row}) => (<>
                {row.submitter.entityType === "group" ? <GroupIcon fontSize="large" /> : <Person fontSize="large" />}
            </>)
        },
        {
            id: "orgKey",
            label: "Organization",
            isSortable: true,
            props: {width: "10%", align: "center"},
            Content: ({row}) => <Typography fontWeight={500}>{row.orgKey}</Typography>
        },
        {
            id: "name",
            label: "Name",
            isSortable: false,
            props: {width: "60%", align: "left"},
            Content: ({row}) => (<Box p={2}>
                <h3>{row.submitter.npiList.map(it => it.name).join(", ")}</h3>
                    {row.submitter.entityType === "group" && <>Group Name: <strong>{row.submitter.groupName}</strong><br/></>}
                    Created By: <strong>{row.submitter.drcfUserName}</strong>&nbsp;<br/>
                    TIN: <strong>{row.submitter.tin}</strong>&nbsp;<br/>
                    NPI: <strong>{row.submitter.npiList.map(it => it.npi).join(", ")}</strong>&nbsp;<br/>

                 { !!row.submitter.uploadedVerificationDocumentBucketKey && <>
                    Supporting Document: <Link href={"#"} onClick={(e) => on1500FormClick(e, row.orgShortname, row.submitter.id)}>1500Form.pdf</Link>
                    &nbsp;<br/>
                </>}
                {row.submitter.submissionOpen && <>
                    Submission Window: <strong>{row.submitter.submissionOpen} - {row.submitter.submissionClose}</strong>&nbsp;<br/>
                </>}
            </Box>)
        },
        {
            id: "submissionOpen",
            label: "Submission Window",
            isSortable: false,
            props: {width: "10%", align: "center"},
            Content: ({row}) => {
                if(!!row.submitter.submissionOpen && !!row.submitter.submissionOpen) {
                    return (
                        <Typography fontWeight={500}>
                            {moment(row.submitter.submissionOpen).format()} - {moment(row.submitter.submissionClose).format()} <br />
                            Overridden
                        </Typography>
                    );
                }
                if(row.submitter.status === "COMPLETE") {
                    return (
                        <Typography fontWeight={500} color={"rgba(127,127,127,.5)"}>
                            {moment(yearInfo.submissionOpen).format()} - {moment(yearInfo.submissionClose).format()}
                            <br/>
                            Default
                        </Typography>
                    );
                }
                return "-";
            }
        },
        {
            id: "entityType",
            label: "Type",
            isSortable: true,
            props: {width: "10%", align: "center"},
            Content: ({row}) => <Typography fontWeight={500}>{getTypeLabel(row.submitter.entityType)}</Typography>
        },
        {
            id: "status",
            label: "Status",
            isSortable: true,
            props: {width: "20%", align: "center"},
            Content: ({row}) => {
                const statusInfo = getStatus(row.submitter.status);
                return (
                    <Typography fontWeight={500} color={statusInfo.color}>{statusInfo.label}</Typography>
                );
            }
        }
    ];
};

const HomeTable = ({
    isLoading,
    items = [],
    onEditClick,
    year,
    yearInfo,
    organization,
    view,
    implementation,
    type = "individual",
    page = 0,
    size = 10,
    order = "asc",
    sort = "orgKey",
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
    search
}) => {
    const classes = useStyles();

    const handle1500FormClick = (e, orgKey, id) => {
        e.preventDefault();
        api.getConsentDocument(year, orgKey, implementation.key, id);
    };

    const columns = buildTableColumns(classes, type, handle1500FormClick, yearInfo);
    columns.push(
        {
            id: "action",
            isSortable: false,
            label: "",
            props: { align: "right" },
            Content: ({row}) => {
                let buttonLabel = "View Details";
                if(row.submitter.status !== "PENDING_VERIFICATION" && row.submitter.status !== "COMPLETE") {
                    return null;
                }
                if(row.submitter.status === "COMPLETE") {
                    buttonLabel = "Edit Details";
                }
                return (
                    <Button
                        dense="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => onEditClick(row)}
                    >
                        {buttonLabel}
                    </Button>
                );
            }
        }
    );
    return (
        <Paper m={3}>
            <div className={classes.enhancedTable}>
                <EnhancedTable
                    isLoading={isLoading}
                    rows={items}
                    showHeader={true}
                    columns={columns}
                    dense={false}
                    hover={false}
                    emptyMessage={
                        <Box p={8} py={14} textAlign={"center"}>
                            {view === "verification" && <Typography>
                                 There are no Submitting Entity awaiting TIN verification for {year} - {organization.name}.
                            </Typography>}
                            {view !== "verification" && <Typography variant={"subtitle1"} sx={{opacity: .5}}>
                            Click the "Add Submitting Entity" button in the top right to begin registration.
                          </Typography>}
                        </Box>
                    }
                    pagination={true}
                    total={ items.length >= size ? size * (page+1) + 1 : size * (page+1) }
                    rowsPerPage={size}
                    page={page}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onRequestSort={onRequestSort}
                    rowsPerPageOptions={[5, 10, 25]}
                    orderBy={sort}
                    order={order}
                />
            </div>
        </Paper>
    );
};

HomeTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    npis: PropTypes.array.isRequired,
    yearInfo: PropTypes.object.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onReceiptClick: PropTypes.func.isRequired
};

export default HomeTable;
